import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router';
import { pageRoute } from './pageRoute.js';


const routes = [
	{
	  path: '/',
	  name: 'Home',
	  component:()=>import('../views/home')
	},
	{
	  path: '/default',
	  name: 'Default',
	  component:()=>import('../views/default'),
	  children:pageRoute
	},
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
