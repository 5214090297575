import {
	createStore
} from 'vuex'
import formate from "@/utils/formate.js";
// 国际化相关代码
try {
	// 1. 分析用户已经选择的语言 
	var userLang = window.localStorage.getItem("userLang") || '';

	if (userLang.langType == 'CN') {
		var lang = require('../language/zh.js');
	} else {
		var lang = require('../language/en.js');
	}
} catch (e) {
	// error
}

export default createStore({
	state: {
		account: '',
		accountFm: '',
		chainId: '',
		isNet:'',
		lang: lang
	},
	mutations: {
		initAccount(state, data) {
			state.account = data;
			state.accountFm = data ? formate.formatAddr(data) : '';
			localStorage.setItem('account', data);
		},
		initChain(state,chainId) {
			state.chainId = chainId;
			localStorage.setItem('chainId', chainId);
		},
		initNet(state,net){
			console.log(net);
			state.isNet = net;
			localStorage.setItem('isNet', net);
		},
		changeLang(state, tapIndex) {
			if (tapIndex == 0) {
				lang = require('../language/en.js');
			} else {
				lang = require('../language/zh.js');
			}
			state.lang = lang;

			window.localStorage.setItem("userLang", lang);

		}
	},
	actions: {

	}
})
