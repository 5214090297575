<template>
	<router-view />
</template>

<script setup>
	import {useStore} from 'vuex';
	const store = useStore();
	
	const localAccount = localStorage.getItem('account');
	const localChainId = localStorage.getItem('chainId');
	const isNet = localStorage.getItem('isNet');
	
	if(isNet && isNet==1){
		store.commit('initAccount',localAccount);
		store.commit('initChain', localChainId);
		store.commit('initNet', isNet);
	}
	
</script>

<style>
	@import url("./assets/css/bootstrap.min.css");
	@import url("./assets/css/animate.min.css");
	@import url("./assets/fonts/iconfont.css");
	@import url("./assets/css/common.css");
</style>
