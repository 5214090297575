module.exports = {
	langType : 'EN',
	langLanguage:'English',
	language:'Language',
	
	// top bar
	connectTxt:'connect',
	
	// home page
	dashboardText:'Dashboard',
	vcPoolText:'VC Pool',
	bondText:'Bond',
	stackeText:'Stake',
	faqText:'FAQ',
	guidText:'Guild',
	enterApp:'Enter APP',
	
	// left menu
	rankingText:'Ranking',
	bullNftText:'Bull NFT',
	birgeText:'Bridge',
	governanceText:'Governance',
	docsText:'Docs',
	profileText:'Profile',
	
	// tips
	commingSoon:'Comming Soon',
	warningTips:'warning',
	metaMaskTips:'Looks like you need a Dapp browser to get started.',
	metaMaskTips1:'Consider installing MetaMask!',
	
	//home page
	marketplaceText:'NFT MARKETPLACE',
	metaceresText:'W3 DAO METAVERSE',
	homeTitle:'Asset Management DAO On Web3',
	homeDesc:'DAO is owned by W3B & W3C holders. W3B & W3C holders determine the directions and decisions of W3 DAO through on-chain proposal and voting.',
	overviewText:'OVERVIEW',
	treasury:'Treasury balance',
	ROI:'ROI',
	members:'Number of holders',
	portfolio:'Projects',
	newOrganizetion:'NEW ORGANIZATION',
	newOrganizetionDesc:'W3 DAO aims to reshape on-chain economy by providing automated, composable and decentralized asset management service to investors, companies or DAOs through the smart contract.',
	stackeDesc:'Staking W3B / W3C to earn high yield. Up to 298.43% APY.',
	bullNftDesc:'Dentity of DAOs and Nodes.',
	governanceDesc:'W3B & W3C holders participate actively in W3 DAO community governance.',
	howToJoin:'How to participate',
	mintText:'Mint',
	bondDesc:'Mint W3B with Stablecoins. Currently supports: USDT, USDC, BUSD, etc.',
	vcPoolDesc:'Mint W3B with Crypto Assets. Currently supports: BTC, ETH, BNB, FIL, etc.',
	community:'COMMUNITY',
	twitterMember:'Twitter Followers',
	discordMember:'Discord Members',
	ecology:'ECOLOGY',
	ecologyDesc1:'Mint W3B with Stablecoins. Currently supports: USDT, USDC, BUSD, etc.',
	ecologyDesc2:'The latest news in cryptocurrency, current trends of cryptocurrency investment.',
	ecologyDesc3:'Join Ambassador program and become the owner of W3 DAO.',
	ecologyDesc4:'Participate in offline activities, share your investment experience.',
	news:'NEWS',
	newsList:[
		{
			title:'Understanding “Next Big Wave” in Crypto – Ventures',
			date:'FRI,Jul 22,2022,02:12pm UTC',
			info:'Conventional venture capital market is monopolized by big institutions. Ventures DAO provides retail investors an equal opportunity to participate in potential projects ...'
		},
		{
			title:'W3 DAO: ”New Paradigm” of Ventures DAO',
			date:'FRI,Jul 22,2022,02:12pm UTC',
			info:'There is a saying in the crypto industry that 2022 will be the leap year of DAO. DAO has come under the spotlight of the crypto world.As people discovered the crypto world, Ventures DAO started to shine, attracting ...'
		}
	],
	commonFAQ:'IN THE FAQ',
	faqList:[
		{
			title: 'What is W3DAO？',
			info: [
				'W3DAO is an open platform for investors to make investments into major cryptocurrencies (BTC, ETH etc.) and early-stage decentralized applications. It is also an open platform for proposals that are voted upon by DAO NFT holders. W3DAO aims to create a digital asset management protocol for the emerging Web3 ecosystem and to support the decentralized economy. W3DAO embraces the community-oriented membership and offers extreme flexibility in participation that is superior to traditional “asset management” models.',
			],
			state:false
		},
		{
			title: 'What is W3B？',
			info: [
				'W3B is an equity token and can be minted through contributing to the treasury.'
			],
			state:false
		},
		{
			title: 'What is W3C？',
			info: [
				'W3C is used as a liquidity token and can be obtained by staking or through the secondary market.'
			],
			state:false
		},
		{
			title: 'What’s difference between W3B and W3C？',
			info: [
				'W3B and W3C can be exchanged at a ratio of 1:1, 20% transaction tax will be imposed during the process. The transaction tax is allocated as follows: 10 percent will be sent to the Treasury, 5% will be transferred to the LP pools, and 5% will be allocated to commission fees with 2.5% used for buyback and 2.5% of coins burnt for operation.'
			],
			state:false
		},
		{
			title: 'Why should you be staking？',
			info: [
				'Individuals that stake W3B or W3C will enjoy interests from their capital, relevant voting powers are also distributed to those who stake. Furthermore, staking W3B provides dividends from the treasury in various forms as seen below.'
			],
			state:false
		},
		{
			title: 'What differs between DAO and Nodes?',
			info: [
				'In the W3 DAO protocol, both DAOs and nodes have exclusive NFTs. The NFTs can be transferred, with the rights and interests transferred as well. A limited number of applications will be made for DAOs.'
			],
			state:false
		},
		{
			title: 'How will the proceeds be distributed?',
			info: [
				'The continuous appreciation of the Treasury that holders who stake W3B can enjoy dividends of the Treasury in various forms such as stablecoins, major cryptocurrencies or W3C. To be more specific, 80% of the Treasury returns will be rewarded to holders, 10% will be rewarded to the top 50 DAOs, and the remaining 10% will be used for operation.'
			],
			state:false
		},
		{
			title: 'What is the investment strategy of the Treasury?',
			info: [
				'The W3 DAO Treasury will provide as much funding support as possible to proposals pending approval to build the network and ecosystem. It will also be used to invest in high-quality projects by initiating spending proposals through the DAO governance. The Treasury will initially adopt the beta investment strategy and will only invest in leading crypto assets. Revenue will then be generated by becoming involved in De-Fi lending contracts and asset appreciation.'
			],
			state:false
		},
		{
			title: 'How do I get involved?',
			info: [
				'You may become involved through contributing to the treasury in exchange for W3B or providing new ideas and support to our current projects. Another way to become involved is purchasing W3C coins through the secondary market.'
			],
			state:false
		},
	],
	products:'Products',
	learn:'Learn',
	contactUs:'Contact us',
	
	// dashboard
	dashboardList:[
		{
			title:'Treasury balance',
			info:'Current Treasury Balance：Bond pool + VC pool',
			num:0
		},
		{
			title:'W3B/W3C APY',
			info:'W3C annualized yield, the expected annual yield for each W3C',
			num:'298.43%'
		},
		{
			title:'Circulating Supply W3B',
			info:'The total circulating supply of W3B',
			num:0
		},
		{
			title:'Market Cap',
			info:'Current Price x Circulating Supply of W3C;The total market value of a cryptocurrency`s circulating supply.',
			num:0
		},
		{
			title:'W3C Price',
			info:'The price of W3C.',
			num:0
		},
		{
			title:'Burned $W3C',
			info:'The Amount of W3C burned and its approx. USD value.',
			num:0
		}
	],
	treasuryVault:'Treasury Vault',
	investmentStrategy:'Investment Strategy',
	dividend:'Dividend',
	investmentInfo:'“Beta” : W3 DAO Treasury adopts the “Beta” investment strategy initially. W3 DAO will allocate its fund to the top-class crypto assets, generating revenue through the Defi lending protocol or by asset appreciation.',
	investmentInfo1:'“Alpha”: As the W3 DAO community continues to grow, and with the involvement of DAOs and institutions, Treasury will actively apply the “Alpha” investment strategy under DAOs governance and maximize the returns through asset appreciation.',
	assetsList:'Assets List',
	
	// VC-POOL
	vcPoolInfo:'Mint W3B with Crypto Assets.Currently supports:BTC, ETH, BNB, FIL, etc.',
	remainingQuatity:'Remaining Quantity | Max. quantity',
	price:'Price',
	token:'Token',
	amountW3B:'Amount of W3B',
	totalMinted:'Total Minted',
	buy:'buy',
	addToWallet:'Add To Wallet',
	approve:'Approve',
	mint:'Mint',
	redeem:'Redeem',
	instantPayout:'Instant Payout',
	claim:'Claim',
	yourBalance:'Your Balance',
	WillGet:'You Will Get',
	maxMint:'Max You Can Mint',
	vestingTerm:'Vesting Term',
	PendingRewards:'Pending Rewards',
	ClaimableReward:'Claimable Reward',
	TimefullyVested:'Time Until fully vested',
	assetsListInfo:'More assets can be added to VC Pool through DAOs community voting',
	Networks:'Networks',
	AvailableAssets:'Available Assets',
	ObservingAssets:'Observing Assets',
	Name:'Name',
	Balance:'Balance',
	ProposalDAO:'Proposal DAO',
	
	// bull NFT
	NftPageTitle:'Super Bull Decentralized Identity',
	NftPageTitleSub:'You Are Eligible To Mint',
	w3bull:'Super Bull',
	SuperBull:'Super Bull NFT',
	NftPageInfo:[
		'Super Bull is a series of NFT designed exclusively for DAOs and Nodes. Every DAO or Node has its own NFT.NFTs together with their DAOs & Nodes rights and benefits can be freely transferred.',
		'Both DAOs and Nodes have the right and ability to raise an idea or submit a proposal about the W3 DAOs governance or management. This includes but is not limited to the allocation of fund, partnership with other protocols, and deployment in other blockchain.',
		'Top 50 DAOs have the right to vote, and the proposal will be adopted and implemented if the approval vote reaches 67%.'
	],
	
	// profile
	myDaos:'My DAOs',
	myNodes:'My Nodes',
	myDaosNo:'You currently do not have a Daos NFT that belongs to you. Would you like to add one?',
	myNodesNo:'You currently do not have a Nodes NFT that belongs to you. Would you like to add one?',
	
	
	// guild
	walletAddress:'MY WALLET ADDRESS',
	nftNum:'MY NFT NUM',
	nftLvl:'MY NFT LVL',
	stakedW3B:'MY STAKED W3B',
	teamStakeW3B:'MY TEAM STAKED W3B',
	rewardLVL:'MY REWARD LVL',
	rewardToClaim:'REWARD to Claim',
	
	myTeam:'MY TEAM',
	memberWalletAddress:'MEMBER Wallet Address',
	memberStackedW3b:'MEMBER STAKED W3B',
	memberStackedW3C:'MEMBER STAKED W3C',
	memberTeamStackedW3b:'MEMBER TEAM STAKED W3B',
	memberTeamStackedW3C:'MEMBER TEAM STAKED W3C',
	
	
	// invite
	MyInvitations:'My Invitations',
	DirectInvitation:'Direct Invitation',
	TotalRewards:'Total Rewards',
	PendingTxt:'Pending',
	ReceiveTxt:'Receive',
	CopyTxt:'Copy',
	inviteTips:'No invitation data',
	
	//stake
	days:'days',
	nextRebase:'to next rebase',
	TotalValueStaked:'Total Value Staked',
	CurrentIndex:'Current Index',
	APY:'APY',
	UnStake:'UnStake',
	Wrap:'Wrap',
	MAX:'MAX',
	YouWillGet:'You Will Get',
	Earned:'Earned',
	Calculator:'Calculator',
	EstimateReturn:'Estimate Return',
	NextRewardYield:'Next Reward Yield',
	Total:'Total',
	
}