import { poolChildrenRoute } from './childrenRoute.js';
export const pageRoute = [
	{
	  path: '/nft',
	  name: 'Nft',
	  component:()=>import('../views/nft')
	},
	{
	  path: '/profile',
	  name: 'Profile',
	  component:()=>import('../views/profile')
	},
	// {
	//   path: '/dashboard',
	//   name: 'Dashboard',
	//   component:()=>import('../views/dashboard')
	// },
	// {
	//   path: '/pool',
	//   name: 'Pool',
	//   component:()=>import('../views/pool'),
	//   children:poolChildrenRoute
	// },
	{
	  path: '/bond',
	  name: 'Bond',
	  component:()=>import('../views/bond')
	},
	{
	  path: '/stake',
	  name: 'Stake',
	  component:()=>import('../views/stake')
	},
	// {
	//   path: '/guild',
	//   name: 'Guild',
	//   component:()=>import('../views/guild')
	// },
	{
	  path: '/bind/:id?',
	  name: 'Bind',
	  component:()=>import('../views/bind')
	},
	{
	  path: '/invite/:id?',
	  name: 'Invite',
	  component:()=>import('../views/invite')
	},
	
	// {
	//   path: '/report',
	//   name: 'Report',
	//   component:()=>import('../views/report')
	// },
];