const formatAddr = (addr)=>{
	let len = addr.length,
	address = addr.substr(0,4) + ' ... ' + addr.substr(len-4,len);
	return address;
}

const formatDecimal = (num, length)=>{
	num = (parseFloat(num)+0.0000000005).toFixed(10).toString();
	let index = num.indexOf('.')
	if (index !== -1) {
		num = num.substring(0, length + index + 1);
	} else {
		num = num.substring(0);
	}
	return parseFloat(num).toFixed(length);
}

export default {
	formatAddr,
	formatDecimal
}